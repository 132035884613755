<template>
  <div class="reviseWorkManagement">
    <el-dialog
      title="修改(排班管理)"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="客服姓名" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="班次" :label-width="formLabelWidth">
          <el-select
            size="small"
            @change="checkClass"
            v-model="form.job"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in classOptions"
              :key="index"
              :label="item.workName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-checkbox-group v-model="form.key">
            <el-checkbox
              v-for="(item, index) in check"
              :key="index"
              :label="item.id"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getClassList_apiak } from "../../../apis/customer";
export default {
  props: {
    getWork: {
      type: Function,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "", // 客服姓名禁止修改
        key: [], // 渠道
        job: "", // 班次
        date:[], // 日期
      },
      formLabelWidth: "80px",
      //多选框
      check: [
        { id: "1", value: "艺龙", ischeck: false },
        { id: "2", value: "飞猪", ischeck: false },
        { id: "3", value: "美团", ischeck: false },
        { id: "4", value: "去哪儿", ischeck: false },
        { id: "5", value: "携程", ischeck: false },
        { id: "6", value: "百度", ischeck: false },
        { id: "7", value: "萝卜", ischeck: false },
        { id: "8", value: "房仓天下", ischeck: false },
      ],
      //下拉框选择
      classOptions: [], // 班次下拉
      modifyObj: {}, // 修改发送接口用的
      dayClassList: [], // 早中晚班
    };
  },
  created() {
    var now = new Date();
    var startDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    var endDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    this.form.date = [startDate, endDate];
    // this.getWork("", "", this.form.date[0], this.form.date[1]); // 获取数据  // 初始化不应该调用此函数
    // 获取早班中班晚班
    this.getDayClass();
  },
  methods: {
    // 获取早中晚班
    async getDayClass() {
      let { code, data } = await getClassList_apiak({
        page: 1,
        rows: 999,
      });
      console.log(data.records, "获取早中晚");
      this.classOptions = data.records; // 赋值
    },
    open(row) {
      this.form.name = row.managerName;
      this.modifyObj = row; // 赋值
      this.dialogFormVisible = true;
      this.classOptions.label = row.workName;
      this.form.job = row.workName;
      console.log(row, "当前条数据");
      this.form.key = []; // 初次清空
      row.platformIds.forEach((item) => {
        this.check.forEach((val) => {
          if (val.value === item) {
            this.form.key.push(val.id);
          }
        });
      });
    },
    checkClass(item) {
      console.log(item, "选择班型");
      this.id = item;
    },
    //确认保存
    onSubmit() {
      this.modifyData(); // 调用修改方法
      this.dialogFormVisible = false;
    },
    // 修改数据功能函数
    modifyData() {
      this.$axios({
        url: "/otaHotel/workRoster/creat",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          handlerId: this.modifyObj.handlerId,
          id: this.modifyObj.id,
          isDelete: 1,
          managerId: this.modifyObj.managerId,
          managerName: this.modifyObj.managerName,
          platformIds: this.form.key.join(","),
          updateTime: this.timeString,
          workDate: this.modifyObj.workDate.substr(0, 10),
          workName: this.form.job,
          workTemplateId: this.id,
        },
      })
        .then((res) => {
          console.log(res, "修改成功的响应数据");
          if (res.data.code == 0 && res.data.data == "修改成功") {
            this.$message({
              type: "success",
              message: "恭喜您成功修改数据!",
            });
            this.getWork("", "", this.form.date[0], this.form.date[1]); // 获取数据
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error, "修改失败的响应数据");
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding: 30rpx 0rpx !important;
  box-sizing: border-box;
}
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 85%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>