<template>
  <div class="reviseWorkManagement">
    <el-dialog
      title="添加(排班模板)"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="客服姓名" :label-width="formLabelWidth">
          <el-select
            size="small"
            v-model="form.name"
            @change="checkCustomer"
            value-key="value"
            placeholder="请选择客服"
          >
            <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班次" :label-width="formLabelWidth">
          <el-select size="small" v-model="form.job" placeholder="请选择班次">
            <el-option
              v-for="(item, index) in classOptions"
              :key="index"
              :label="item.workName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-checkbox-group v-model="form.key">
            <el-checkbox
              v-for="(item, index) in check"
              :key="index"
              :label="item.id"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRosterManagerList_apiak,
  getClassList_apiak,
} from "../../../apis/customer";
import local from "@/utils/local.js";
export default {
  props: {
    getScheduling: {
      type: Function,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "", // 客服姓名(id)
        key: [], // 渠道
        job: "", // 班次
      },
      formLabelWidth: "80px",
      //多选框
      check: [
        { id: "1", value: "艺龙" },
        { id: "2", value: "飞猪" },
        { id: "3", value: "美团" },
        { id: "4", value: "去哪儿" },
        { id: "5", value: "携程"},
        { id: "6", value: "百度"},
        { id: "7", value: "萝卜"},
        { id: "8", value: "房仓天下"},
      ],
      classOptions: [], // 班次下拉
      customerOptions: [], // 客服下拉
      timeString: "", // 接口请求时间
    };
  },
  created() {
    Date.prototype.Format = function (fmt) {
      // author: meizz
      var o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    };
    this.timeString = new Date().Format("yyyy-MM-dd hh:mm:ss");
  //  console.log(this.timeString, "事件");
    this.getDayClass(); // 获取排班列表
    this.getCustomerFn(); // 获取客服列表
  },
  methods: {
    // 获取早中晚班
    async getDayClass() {
      let { code, data } = await getClassList_apiak({
        page: 1,
        rows: 999,
      });
      this.classOptions = data.records; // 重新赋值
    //  console.log(this.classOptions, "排班");
    },
    // 打开弹窗
    open() {
      this.form.key=[];  // 初始化打开弹窗
      this.form.name=''; // 初始化为空
      this.form.job=''; // 初始化为空
      this.dialogFormVisible = true;
    },
    // 获取客服人员列表
    async getCustomerFn() {
      let { code, data } = await getRosterManagerList_apiak({
        page: 1,
        rows: 999,
      });
    //  console.log(data, "获取客服人员列表");
      this.customerOptions = data;
    },
    // 选择客服
    checkCustomer(item) {
   //   console.log(item, "选择客服");
    },
    //确认保存
    onSubmit() {
      this.addTemplateFn(); // 调用添加函数
      this.getScheduling(); // 重绘视图
      this.dialogFormVisible = false;
    },
    // 添加班次功能函数
    addTemplateFn() {
      this.$axios({
        url: "/otaHotel/workUseTemplate/creat",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          workUseTemplateList: [
            {
              platformIds: this.form.key.join(","),
              managerId: this.form.name,
              workTemplateId: this.form.job,
              headerManagerId: local.get("releaseScheduling"),
            },
          ],
        },
      })
        .then((res) => {
      //    console.log(res, "添加成功的响应数据");
          if (res.data.code == 0 && res.data.data == "新增或修改成功！") {
            this.$message({
              type: "success",
              message: "恭喜您成功添加数据!",
            });
            this.getScheduling(); // 重绘视图
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error, "添加失败的响应数据");
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding: 30rpx 0rpx !important;
  box-sizing: border-box;
}
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 85%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>