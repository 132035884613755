<template>
  <div class="workManagement">
    <!-- 排班管理 -->
    <div class="serch">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="日期">
          <el-date-picker
            v-model="form.date"
            size="small"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客服姓名">
          <el-input
            size="small"
            v-model="form.user"
            placeholder="请输入要查询的客服姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="班型">
          <el-select size="small" v-model="form.classType" placeholder="请选择">
            <el-option
              v-for="(item, index) in classOptions"
              :key="index"
              :label="item.workName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 排班表格 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button
            size="mini"
            :class="btnCur == index ? 'active' : ''"
            v-for="(item, index) in btn"
            :key="index"
            @click="btnFn(index)"
            >{{ item.name }}</el-button
          >
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          fixed
          prop="managerName"
          label="客服姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="platformIds" label="渠道" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.platformIds.join(",") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="班型" prop="" align="center">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.workName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="上班状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.workStatus == '下班'">{{
              scope.row.workStatus
            }}</el-tag>
            <el-tag type="success" v-if="scope.row.workStatus == '上班中'">{{
              scope.row.workStatus
            }}</el-tag>
            <el-tag type="info" v-if="scope.row.workStatus == '休息中'">{{
              scope.row.workStatus
            }}</el-tag>
            <el-tag type="danger" v-if="scope.row.workStatus == '未打卡'">{{
              scope.row.workStatus
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="160px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          width="160px"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button type="danger" size="mini" @click="deleteData(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改排班管理弹出框 -->
    <ReviseWorkManagement
      :getWork="getWork"
      ref="reviseWorkManagement"
    ></ReviseWorkManagement>
  </div>
</template>

<script>
import ReviseWorkManagement from "./ReviseWorkManagement.vue";
import {
  workList_api,
  deleteOneByIds_apiak,
  getClassList_apiak,
} from "../../../apis/customer";
export default {
  components: {
    ReviseWorkManagement,
  },
  data() {
    return {
      // 选项卡按钮
      btnCur: "0",
      // 角色查询新增
      form: {
        user: "", // 客服姓名
        classType: "", // 班型
        date: "", // 日期选择
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //   角色表格
      managerName: "", // 客服姓名
      //选项按钮
      btn: [
        { name: "全部" },
        { name: "艺龙" },
        { name: "飞猪" },
        { name: "美团" },
        { name: "去哪儿" },
        { name: "携程" },
        { name: "百度" },
        { name: "萝卜" },
        { name: "房仓天下" },
      ],
      //日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      classOptions: [], // 班次选择(早中晚)
      oldList: [],
    };
  },
  created() {
    var now = new Date();
    var startDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    var endDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    this.form.date = [startDate, endDate];
    this.getWork("", "", this.form.date[0], this.form.date[1]); // 获取数据
    this.getDayClass(); // 获取排班(早中晚)
  },
  methods: {
    // 获取早中晚班
    async getDayClass() {
      let { code, data } = await getClassList_apiak({
        page: 1,
        rows: 999,
      });
      console.log(data.records, "获取早中晚");
      this.classOptions = data.records; // 赋值
    },
    // 选项卡点击事件
    btnFn(index) {
      let that = this;
      if (index == 0) {
        that.btnCur = "";
        if(that.form.date==null){
          that.form.date=[];
          that.form.date[0]='';
          that.form.date[1]='';
          that.getWorkListByBtn(that.form.classType,that.form.user,that.btnCur,that.form.date[0],that.form.date[1]); // 按钮查询函数
        }else{
          that.getWorkListByBtn(that.form.classType,that.form.user,that.btnCur,that.form.date[0],that.form.date[1]); // 按钮查询函数
        }
      } else {
        that.btnCur = index;
        if(that.form.date==null){
          that.form.date=[];
          that.form.date[0]='';
          that.form.date[1]='';
          that.getWorkListByBtn(that.form.classType,that.form.user,that.btnCur,that.form.date[0],that.form.date[1]); // 按钮查询函数
        }else{
          that.getWorkListByBtn(that.form.classType,that.form.user,that.btnCur,that.form.date[0],that.form.date[1]); // 按钮查询函数
        }
      }
    },
    //分页
    handleSizeChange(val) {
      this.count = val;
      this.getWork(that.form.classType,that.form.user, this.form.date[0], this.form.date[1]); // 获取数据
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getWork(that.form.classType,that.form.user, this.form.date[0], this.form.date[1]); // 获取数据
    },
    //获取排班管理列表
    async getWork(workTemplateId, managerName, startTime, endTime) {
      let { code, data } = await workList_api({
        page: this.currentPage,
        rows: this.count,
        workTemplateId: workTemplateId,
        managerName: managerName,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        console.log(data, "获取排班管理列表数据");
        this.oldList = data.records;
        this.total = data.total;
        let arr = [
          { id: "1", value: "艺龙" },
          { id: "2", value: "飞猪" },
          { id: "3", value: "美团" },
          { id: "4", value: "去哪儿" },
          { id: "5", value: "携程" },
          { id: "6", value: "百度" },
            { id: "7", value: "萝卜" },
            { id: "8", value: "房仓天下" },
        ];
        data.records.forEach((item, index) => {
          let newArr = [];
          arr.forEach((arrItem) => {
            if (item.platformIds.indexOf(arrItem.id) !== -1)
              newArr.push(arrItem.value);
          });
          data.records[index].platformIds = newArr;
        });
        this.tableData = data.records;
      }
    },
    // 按钮查询功能函数
    async getWorkListByBtn(
      workTemplateId,
      managerName,
      platformId,
      startTime,
      endTime
    ) {
      if (this.form.date == null) {
        this.form.date = [];
        this.form.date[0] = "";
        this.form.date[1] = "";
        let { code, data } = await workList_api({
          page: this.currentPage,
          rows: this.count,
          workTemplateId: workTemplateId,
          managerName: managerName,
          platformId: platformId,
          startTime: startTime,
          endTime: endTime,
        });
        if (code == 0) {
          console.log(data, "获取排班管理列表数据");
          this.oldList = data.records;
          this.total = data.total;
          let arr = [
            { id: "1", value: "艺龙" },
            { id: "2", value: "飞猪" },
            { id: "3", value: "美团" },
            { id: "4", value: "去哪儿" },
            { id: "5", value: "携程" },
            { id: "6", value: "百度" },
            { id: "7", value: "萝卜" },
            { id: "8", value: "房仓天下" },
          ];
          data.records.forEach((item, index) => {
            let newArr = [];
            arr.forEach((arrItem) => {
              if (item.platformIds.indexOf(arrItem.id) !== -1)
                newArr.push(arrItem.value);
            });
            data.records[index].platformIds = newArr;
          });
          this.tableData = data.records;
        }
      } else {
        let { code, data } = await workList_api({
          page: this.currentPage,
          rows: this.count,
          workTemplateId: workTemplateId,
          managerName: managerName,
          platformId: platformId,
          startTime: startTime,
          endTime: endTime,
        });
        if (code == 0) {
          console.log(data, "获取排班管理列表数据");
          this.oldList = data.records;
          this.total = data.total;
          let arr = [
            { id: "1", value: "艺龙" },
            { id: "2", value: "飞猪" },
            { id: "3", value: "美团" },
            { id: "4", value: "去哪儿" },
            { id: "5", value: "携程" },
            { id: "6", value: "百度" },
            { id: "7", value: "萝卜" },
            { id: "8", value: "房仓天下" },
          ];
          data.records.forEach((item, index) => {
            let newArr = [];
            arr.forEach((arrItem) => {
              if (item.platformIds.indexOf(arrItem.id) !== -1)
                newArr.push(arrItem.value);
            });
            data.records[index].platformIds = newArr;
          });
          this.tableData = data.records;
        }
      }
    },
    //修改排班管理
    revise(row) {
      this.$refs.reviseWorkManagement.open(row);
    },
    // 查询
    search() {
      if (this.form.date == null) {
        this.form.date = []; // 空数组
        this.form.date[0] = "";
        this.form.date[1] = "";
        this.getWork(
          this.form.classType,
          this.form.user,
          this.form.date[0],
          this.form.date[1]
        );
      } else {
        this.getWork(
          this.form.classType,
          this.form.user,
          this.form.date[0],
          this.form.date[1]
        );
      }
    },
    // 点击删除排班事件处理函数
    deleteData(row) {
      console.log(row, "需要删除的当前行");
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteDataFn(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除排班功能函数
    async deleteDataFn(row) {
      let { code, msg } = await deleteOneByIds_apiak({
        ids: row.id,
      });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getWork("", "", this.form.date[0], this.form.date[1]); // 获取数据
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #ebeff4;
}
/deep/.has-gutter {
  background: #ebf1fa !important;
}
.workManagement {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        button {
          border: 1px solid #256def;
          color: #256def;
        }
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>