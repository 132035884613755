<template>
  <div class="scheduling">
    <!-- 排班模版发布 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="发布日期">
        <el-date-picker
          size="small"
          v-model="formInline.value"
          align="right"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button v-if="role_customerService_scheduling_release" size="mini" type="primary" @click="release">发布</el-button>
        <el-button v-if="role_customerService_scheduling_add" size="mini" type="success" @click="addTemplate"
          >新增</el-button
        >
        <el-button v-if="role_customerService_scheduling_shift" size="mini" type="success" @click="shiftTemplateButton"
          >班次模块</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 排班表格 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button
            size="mini"
            :class="btnCur == index ? 'active' : ''"
            v-for="(item, index) in btn"
            :key="index"
            @click="btnFn(index)"
            >{{ item.name }}</el-button
          >
          <!-- <button v-for="(item,index) in btn" :key="index">{{item.name}}</button> -->
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column prop="" label="客服姓名" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.userName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="渠道">
          <template slot-scope="scope">
            <div>
              {{ scope.row.platformIdsName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="班型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag>{{ scope.row.workName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button type="danger" size="mini" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改排班管理弹出框 -->
    <ModifySchedulingTemplate
      ref="modifySchedulingTemplate"
      :getScheduling="getScheduling"
    ></ModifySchedulingTemplate>
    <!-- 班次模板弹出框 -->
    <ShiftTemplate ref="shiftTemplate"></ShiftTemplate>
    <!-- 添加班次模板弹出框 -->
    <AddTemplate ref="addTemplate" :getScheduling="getScheduling"></AddTemplate>
  </div>
</template>

<script>
import ModifySchedulingTemplate from "./ModifySchedulingTemplate.vue";
import ShiftTemplate from "./ShiftTemplate.vue";
import AddTemplate from "./addTemplate.vue";
import { scheduling_api, deleteByIds_apiak } from "../../../apis/customer";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    ModifySchedulingTemplate,
    ShiftTemplate,
    AddTemplate,
  },
  data() {
    return {
      role_customerService_scheduling_release:getRoleKey('role_customerService_scheduling_release'),
      role_customerService_scheduling_add:getRoleKey('role_customerService_scheduling_add'),
      role_customerService_scheduling_shift:getRoleKey('role_customerService_scheduling_shift'),
      // 选项卡按钮
      btnCur: "0",
      // 角色查询新增
      formInline: {
        value: [],
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
      multipleSelection: [],
      //选项按钮
      btn: [
        { name: "全部" },
        { name: "艺龙" },
        { name: "飞猪" },
        { name: "美团" },
        { name: "去哪儿" },
        { name: "携程" },
        { name: "百度" },
        { name: "萝卜" },
        { name: "房仓天下" },
      ],
      
      //日期选择
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getScheduling(); // 获取排班待发布列表
    var now = new Date();
    var startDate = new Date(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
    )
      .toISOString()
      .slice(0, 10);
    this.formInline.value = startDate;
  },
  methods: {
    getNowDate() {
      this.formInline.value = new Date(); // 赋值
    },
    // 选择日期
    checkDate(item) {
      console.log(item[0], item[1], "选择的日期");
    },
    // 班次模板
    shiftTemplateButton() {
      this.$refs.shiftTemplate.open();
    },
    // 选项卡点击事件
    btnFn(index) {
      if (index == 0) {
        this.btnCur = "";
        this.getScheduling(this.btnCur); // 查询
      } else {
        this.btnCur = index;
        this.getScheduling(this.btnCur); // 查询
      }
    },
    //分页
    handleSizeChange(val) {
      this.count = val;
      this.getScheduling();
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getScheduling();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val; // 当表格选择的数据加入到发布数组中
    },
    //获取排班模板列表
    async getScheduling(platform) {
      let { code, data } = await scheduling_api({
        page: this.currentPage,
        rows: this.count,
        platform: platform,
      });
      if (code == 0) {
        console.log(data);
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    //修改排班管理
    revise(row) {
      this.$refs.modifySchedulingTemplate.open(row);
    },
    // 删除当前客服
    async del(row) {
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增模板
    addTemplate() {
      this.$refs.addTemplate.open();
    },
    // 发布
    release() {
      if (this.multipleSelection.length >= 1) {
        console.log(this.multipleSelection, "需要发布的数组数据");
        this.releaseFn(); // 调用发布函数
        this.multipleSelectio = []; // 选中数组为空
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一个模板进行发布",
        });
      }
    },
    // 发布功能函数
    releaseFn() {
      this.multipleSelection.forEach((item) => {
        this.$axios({
          url: "/otaHotel/workRoster/creat",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            managerId: item.managerId,
            workTemplateId: item.workTemplateId,
            platformIds: item.platformIds,
            workName: item.workName,
            workDate: this.formInline.value,
          },
        })
          .then((res) => {
            console.log(res, "发布的响应数据");
            if (res.data.code == 0 || res.data.msg == "新增成功") {
              this.$message({
                type: "success",
                message: "恭喜您，发布成功，可移至排班管理查看模板",
              });
              this.multipleSelectio = []; // 选中数组为空
            } else {
              this.$message({
                type: "info",
                message: res.data.data,
              });
            }
          })
          .catch((error) => {
            console.log(error, "发布失败的响应数据");
          });
      });
    },
    // 删除功能函数
    async dels(row) {
      let { code, data } = await deleteByIds_apiak({ ids: row.id });
      console.log(code, data, "删除当前排班的响应数据");
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getScheduling();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #ebeff4;
}
/deep/.has-gutter {
  background: #ebf1fa !important;
}
.scheduling {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        button {
          border: 1px solid #256def;
          color: #256def;
        }
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>