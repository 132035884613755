<template>
  <div class="reviseWorkManagement">
    <el-dialog
      title="修改排班模板"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="客服姓名" :label-width="formLabelWidth">
          <el-select
            size="small"
            v-model="form.customer"
            placeholder="请选择"
            @change="checkPerson"
          >
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班次" :label-width="formLabelWidth">
          <el-select size="small" v-model="form.job" placeholder="请选择班次">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.workName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-checkbox-group v-model="form.key">
            <el-checkbox
              v-for="(item, index) in check"
              :key="index"
              :label="item.id"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" type="primary" plain @click="close"
              >取消</el-button
            >
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRosterManagerList_apiak,
  getClassList_apiak,
} from "../../../apis/customer";
import local from "@/utils/local.js";
export default {
  props: {
    getScheduling: {
      type: Function,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        customer: "", // 客服姓名
        key: [],
        job: "", // 班次
      },
      formLabelWidth: "80px",
      //多选框
      check: [
        { id: "1", value: "艺龙", ischeck: false },
        { id: "2", value: "飞猪", ischeck: false },
        { id: "3", value: "美团", ischeck: false },
        { id: "4", value: "去哪儿", ischeck: false },
        { id: "5", value: "携程", ischeck: false },
        { id: "6", value: "百度", ischeck: false },
        { id: "7", value: "萝卜", ischeck: false },
        { id: "8", value: "房仓天下", ischeck: false },
      ],
      options: [], //下拉框选择
      modifyObj: {}, // 修改发送接口用的
      timeString: "", // 接口请求时间
      dayClassList: [], // 早中晚班
      personList: [], // 客服列表
      managerId:'', // 客服id
      workTemplateId:'',  // 班型id
    };
  },
  created() {
    Date.prototype.Format = function (fmt) {
      // author: meizz
      var o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    };
    this.timeString = new Date().Format("yyyy-MM-dd hh:mm:ss");
    this.getDayClass(); // 获取早班中班晚班
    this.getCustomerList(); // 初始化页面获取客服人员列表
  },
  methods: {
    async getCustomerList() {
      let { code, data } = await getRosterManagerList_apiak();
      // console.log(code,data,'获取客服人员列表');
      this.personList = data;
    },
    // 获取早中晚班
    async getDayClass() {
      let { code, data } = await getClassList_apiak({
        page: 1,
        rows: 10,
      });
      console.log(data.records, "获取早中晚");
      this.options = data.records; // 赋值
    },
    open(row) {
      console.log(row, "修改排版模板传递的数据");
      this.form.customer = row.userName; // 客服姓名
      this.modifyObj = row; // 赋值
      this.dialogFormVisible = true;
      this.options.label = row.workName;
      this.form.job = row.workName;
      this.managerId=row.managerId; // 客服id
      this.workTemplateId=row.workTemplateId; // 班型
      console.log(row, "当前条数据");
      this.form.key = []; // 初次清空
      row.platformIds.split(",").forEach((item) => {
        this.check.forEach((val) => {
           if(val.id==item){
            this.form.key.push(val.id)
          }
        });
      });
    },
    // 选择客服
    checkPerson(item) {
      console.log(item, "选择客服");
      this.managerId=item;  // 赋值
    },
    // 选择班型
    checkClass(item) {
      console.log(item, "选择班型");
      this.workTemplateId=item; // 赋值
    },
    //确认保存
    onSubmit() {
      this.modifyData(); // 调用修改方法
      this.dialogFormVisible = false;
    },
    // 修改数据功能函数
    modifyData() {
      this.$axios({
        url: "/otaHotel/workUseTemplate/creat",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          workUseTemplateList: [
            {
              createTime: this.timeString,
              headerManagerId: local.get("releaseScheduling"),
              isDelete: 1,
              id:this.modifyObj.id,
              managerId: this.managerId,
              platformIds: this.form.key.join(","),
              updateTime: this.timeString,
              workTemplateId: this.workTemplateId,
            },
          ],
        },
      })
        .then((res) => {
          console.log(res, "修改成功的响应数据");
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "恭喜您成功修改数据!",
            });
            this.getScheduling(); // 重绘视图
          }
        })
        .catch((error) => {
          console.log(error, "修改失败的响应数据");
        });
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding: 30rpx 0rpx !important;
  box-sizing: border-box;
}
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 85%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>